import { useCRUD } from './useCRUD';
import { cloneDeep } from 'lodash-es';
import { useLanguage } from './useLanguage';
import { PAGE_SIZE_OPTIONS } from '@/constant';
import { onMounted, reactive, watch } from 'vue';
import { useTableConfig } from './useTableConfig';

export function usePaginationTable(config = {}) {
  const { t, PREFIX } = useLanguage();
  const { curPageSize } = useTableConfig();

  const defaultPaginationData = {
    current: 1,
    pageSize: curPageSize.value,
    showTotal: (total, range) => t('showTotal', PREFIX.PAGINATION, { start: range[0], end: range[1], total }),
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showSizeChanger: true,
  };

  const pagination = reactive({
    ...defaultPaginationData,
    ...config.pagination,
  });

  const sorter = reactive(config.sorter || { field: undefined, order: undefined }); // config.sorter 用于指定默认排序

  const apiPaginationKeyMap = config.apiPaginationKeyMap || { page: 'current', size: 'pageSize' };

  const CRUD = useCRUD({ ...config, onReload: sendRequest, preload: false });

  watch(
    () => curPageSize.value,
    to => {
      if (PAGE_SIZE_OPTIONS.includes(to)) {
        resetPagination();
        pagination.pageSize = +to;
        return sendRequest();
      }
    },
  );

  function sendRequest(_pagination = pagination, _sorter = sorter) {
    const params = {};
    Object.entries(apiPaginationKeyMap).forEach(([server, front]) => {
      params[server] = _pagination[front];
    });
    params.page--;

    const { field, order } = _sorter;
    if (field && order) {
      params['sortField'] = Array.isArray(field) ? field.join('.') : field;
      params['sortOrder'] = order === 'ascend' ? 'ASC' : 'DESC';
    }

    // Ken: 必须在onFetch里records时, 同步处理pageSize/total, 否则可能会导致ant table pagination报警告
    return CRUD.onFetch(params, data => {
      pagination.total = data?.totalElements ?? pagination.total;
      pagination.current = data?.number + 1 ?? pagination.current;
      pagination.pageSize = data?.size ?? pagination.pageSize;
      sorter.field = field && order ? field : undefined;
      sorter.order = field && order ? order : undefined;
    });
  }

  function resetPagination() {
    pagination.current = 1;
  }

  function onPaginationChange({ current, pageSize } = {}) {
    const _pagination = cloneDeep(pagination);
    _pagination.current = current;
    if (_pagination.pageSize !== pageSize) {
      _pagination.current = 1;
      _pagination.pageSize = pageSize;
    }
    return sendRequest(_pagination);
  }

  function onSort(sorter = {}) {
    return sendRequest(pagination, sorter);
  }

  function onIndividualPaginationChange(current, pageSize) {
    onPaginationChange({ current, pageSize });
  }

  async function onRemove(id) {
    await CRUD.onRemove(id, false);
    resetPagination();
    return sendRequest();
  }

  function onSearch() {
    resetPagination();
    return sendRequest();
  }

  /**
   * @Author Ken
   * @CreateDate 2021-12-13 18:05
   * @desc remove/add/edit, then reload data
   * @params
   * action: must be Function
   * @return
   */
  async function sendActionThenResearch(action) {
    await CRUD.sendAction(action, false);
    await onSearch();
  }

  onMounted(() => {
    const { preload = true } = config;
    if (preload) {
      return onSearch();
    }
  });

  return {
    pagination,
    sorter,
    ...CRUD,
    onRemove,
    onSearch,
    sendRequest,
    resetPagination,
    onPaginationChange,
    onSort,
    onIndividualPaginationChange,
    sendActionThenResearch,
  };
}
